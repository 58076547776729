export function autofill_by_params(formprefix) {
    let s = window.location.search.substr(1);
    let s2 = s.split("&");
    for (let s3 of s2) {
        let s4 = s3.split("=");
        if (s4.length != 2) {
            continue;
        }
        var obj = $("#" + formprefix + "_" + s4[0]);
        if (obj.length == 0) {
            continue;
        }
        obj.find("option[value=" + s4[1] + "]").attr("selected", "selected");
        obj.trigger("change");
    }
};