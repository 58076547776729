$(document).ready(function() {
    $(".select_search_field").each ((i,e) => {
        let search = $(e);
        let select = search.parent().find("select");
        let options = select.find("option");
        search.on('input',()=> {
            let searchtext = search.val().trim().toLowerCase();
            let chosen = null;
            options.each((i2,option) => {
                option = $(option);
                comparetext = option.text().toLowerCase();
                if (searchtext==="") {
                    if (option.attr('selected') == null) {
                        option.attr("selected", null);
                    }
                    return;
                }
                option.attr("hidden", (comparetext.includes(searchtext) ? null : 'asd'));
                if (chosen == null && comparetext.includes(searchtext)) {
                    chosen = option
                }
            });

            if (chosen == null) {
                options.attr("hidden", null);
                return;
            }

            options.each((i2,e2) => {
                let e3 = $(e2);
                let newval = (e2 == chosen[0] ? 'selected' : null);
                if (e3.attr('selected') !== newval) {
                    e3.attr('selected', newval);
                }
            });
        });
    });
});