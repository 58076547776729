import * as ActiveStorage from "activestorage";

class Upload {
    constructor(file, form, maxsize) {

        ActiveStorage.start();

        this.form = form;
        this.file = file;
        this.input = form.querySelector("input[type=file]");
        this.submit = form.querySelector("input[type=submit]");
        this.state = 'not initialized';

        this.init();
        if (file.size > maxsize) {
            this.error("Datei zu groß. Maximalgröße: " + (maxsize / 1024 / 1024) + "MB");

            this.progressBar.style.width = "100%";
            this.progressBar.setAttribute("aria-valuenow", "100");

            return;
        } else if (!file.type.startsWith("image/") && file.type != 'application/pdf') {
            this.error("Falsches Dateiformat. Es sind nur Bilder und PDFs erlaubt.");

            this.progressBar.style.width = "100%";
            this.progressBar.setAttribute("aria-valuenow", "100");

            return;
        }
        this.directupload = new ActiveStorage.DirectUpload(file, this.input.dataset.directUploadUrl, this);
        this.startUpload();
        this.state = 'running';
    }

    directUploadWillStoreFileWithXHR(request) {
        console.log("init upload");
        request.upload.addEventListener("progress",
            event => this.progress(event))
    }


    startUpload() {
        this.start();
        this.directupload.create((error, blob) => {
            if (error) {
                // Handle the error
                this.state = 'error';
                console.error(error);
            } else {
                // Add an appropriately-named hidden input to the form with a
                //  value of blob.signed_id so that the blob ids will be
                //  transmitted in the normal upload flow
                const hiddenField = document.createElement('input');
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("value", blob.signed_id);
                hiddenField.name = this.input.name;
                this.form.appendChild(hiddenField);
                this.state = 'completed';
                this.completed();
            }
        });
    }

    init() {
        let label = document.createElement("label");
        label.innerText = this.file.name;

        let progress = document.createElement("div");
        progress.classList.add("progress");

        let progressBar = document.createElement("div");
        progressBar.classList.add("progress-bar-striped");
        progressBar.classList.add("progress-bar");
        progressBar.classList.add("bg-secondary");
        progressBar.id = "direct-upload-progress-${id}";
        progressBar.role = "progressbar";
        progressBar.innerText = "0%";
        progressBar.style.width = "0%";
        progressBar.setAttribute("aria-valuenow", "0");
        progressBar.setAttribute("aria-valuemin", "0");
        progressBar.setAttribute("aria-valuemax", "100");

        this.progressBar = progressBar;

        progress.appendChild(progressBar);


        let progressbars = document.getElementsByClassName("progressBars")[0];
        progressbars.prepend(progress);
        progressbars.prepend(label);
    }

    start() {
        this.progressBar.classList.remove("bg-secondary");
        this.progressBar.classList.add("bg-primary");
        this.progressBar.classList.add("active");
    }

    progress(event) {
        // Use event.loaded and event.total to update the progress bar
        let val = Math.ceil(event.loaded / event.total) * 100;

        this.progressBar.innerText = val + "%";
        this.progressBar.setAttribute("aria-valuenow", val);
        this.progressBar.style.width = val + "%";
    }

    error(error) {
        this.progressBar.classList.remove("bg-primary");
        this.progressBar.classList.remove("active");
        this.progressBar.classList.add("bg-danger");
        this.progressBar.innerText = error;
    }

    completed() {
        this.progressBar.classList.remove("active");
        this.progressBar.classList.remove("bg-primary");
        this.progressBar.classList.add("bg-success");

    }
}

export function initFileUpload() {

    const MAXSIZE = 1024 * 1024 * 10; //10Mb

    let uploads = [];

    let ud = $("#upload_document");
    let f = ud.find("form");
    let ff = f.find("input[type=file]");
    let dh = ud.find(".drophere");
    let submit = ud.find("input[type=submit]");
    let is_form_submit = false;

    function addFile(file) {
        let u = new Upload(file, f[0], MAXSIZE);
        uploads.push(u);
    }

    dh.click((e) => {
        ff.trigger('click', e);
    });

    f.on('change', (event) => {
        event.preventDefault();
        Array.from(event.target.files).forEach(addFile);
        event.target.value = null
    });

    dh.on("dragover", (e) => {
        e.preventDefault();
        return true;
    });

    dh.on("drop", (event) => {
        event.preventDefault();
        Array.from(event.originalEvent.dataTransfer.files).forEach(addFile);
    });

    $(window).on('beforeunload', function () {
        console.log("unload");
        if (is_form_submit) {
            return;
        }
        let com = uploads.some((u) => {
            return u.state == "completed"
        });
        let ru = uploads.some((u) => {
            return u.state == "running"
        });
        if ((com || ru)) {
            return 'Nicht hochgeladene Dateien entdeckt!';
        }
    });

    f.on("submit", (event) => {
        let com = uploads.some((u) => {
            return u.state == "completed"
        });
        let ru = uploads.some((u) => {
            return u.state == "running"
        });
        if (ru || !com) {
            event.preventDefault();
            return false;
        }
        is_form_submit = true;
    });
}
