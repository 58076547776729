export function permission_ui() {

    $("input[type=checkbox]").change(
        (e) =>{
            var a = $(e.target);
            var disabled = a[0].checked ? "" : null;
            var o = a.parents(".collapse");
            o.find("select").attr("disabled", disabled);
            if(a[0].checked) {
                o.find("table").addClass('d-none');
            } else {
                o.find("table").removeClass('d-none');
            }
        }
    );
    let p = $("#permissions_admin_manage");
    p.change(
        () => {
            p.parents(".tab-pane").find(".card").first().find(".card").each((i,e)=> {
                if (i === 0) {
                    return;
                } else {
                    if (p[0].checked) {
                        $(e).addClass('d-none');
                    } else {
                        $(e).removeClass('d-none');
                    }
                }
            });
        }
    );
}