export class toggleButton {
    constructor(thebutton) {
        if (thebutton.dataset.ajaxHref === undefined) {
            throw new Error('kein href für toggleButton angegeben');
        }
        this.button = thebutton;
        this.icon = thebutton.querySelector("i");
        this.href = thebutton.dataset.ajaxHref;
        this.method = thebutton.dataset.method || 'get';
        this.running = false;
        $(thebutton).on("click", this.click.bind(this));
    }

    click(e) {
        if (this.running) {
            return;
        }
        this.running = true;
        $.ajax(this.href, {
            method: this.method,
            success: this.success.bind(this),
            error: this.fail.bind(this)
        });
    }

    fail(e) {
        this.running = false;
        console.log(e);
    }

    success(e) {
        this.running = false;
        if (e.state === undefined) {
            throw new Error('ajax request enthält kein state');
        }

        if (e.state) {
            this.button.classList.remove("btn-danger");
            this.button.classList.add("btn-success");
            this.icon.classList.remove("fa-times");
            this.icon.classList.add("fa-check");
        } else {
            this.button.classList.add("btn-danger");
            this.button.classList.remove("btn-success");
            this.icon.classList.add("fa-times");
            this.icon.classList.remove("fa-check");
        }

        console.log(e.state);
    }
}

$(document).ready(()=>{
    (function(){
        $("button[data-ajax-toggle]").each((i,e) => {
            new toggleButton(e);
        });
    }) ();
});