export function initQuickSearch() {

    $("#quicksearch .card").hide();

    $('#quicksearch').on('shown.bs.modal', function () {
        let qsInput = $('#qs-input');
        qsInput.focus();
        qsInput.select();
    }) ;

    $(document).keydown((e) => {
       if (e.keyCode != 113) {
           return;
       }
       $('#quicksearch').modal();

    });

    var qs = $("#quicksearch");
    var qsi = qs.find("#qs-input");
    qsi.on("input", function() {
        var v = qsi.val();
        if (v == "")
        {
            $("#quicksearch .card").hide();
            return;
        }
        $.get("/quicksearch/" + v).then( (e) => {
            for(let i in e.result) {
                var card = qs.find("#qs-" + i);
                var cb = card.find(".card-body");
                var res = e.result[i];
                if (res.length == 0) {
                    card.hide();
                    continue;
                } else {
                    card.show();
                }
                cb.empty();
                for (let j of res) {

                    let html = "<span>" + j.desc + "</span>"
                    if (j.wenumber)
                    {
                        html = "<strong>" + j.wenumber + "</strong><span class='divider'> | </span>" + html;
                    }

                    let a = $("<a>")
                        .addClass("dropdown-item")
                        .html(html)
                        .attr("href", j.url);

                    cb.append(a)
                }
            }
            console.log(e.result);
        });
    });
}