export function tenant_bill_warning(formprefix) {
    var tenant = $("#" + formprefix + "_tenant_id");
    var billwarning = $("#tenant_unpayed_bill");
    tenant.change(() => {
        let v = tenant.val();
        if (v === "") {
            billwarning.addClass("d-none");
            return;
        }
        $.get('/tenants/' + v + '/unpayedbills', (e) => {
            if (e.unpayed) {
                billwarning.removeClass("d-none");
            } else {
                billwarning.addClass("d-none");
            }
        });

    });
}