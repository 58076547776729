export function find_last_digit(formprefix) {
    function a() {
        var v = $("#" + formprefix + "_contract_" + formprefix  + "_id").val();
        if (v === "") {
            return;
        }
        $.get('/apartments/' + v + '/next_last_digit', (e) => {
            $("#" + formprefix + "_contract_last_digit").val(e);
        });
    }

    $(document).ready(function () {

        $("#" + formprefix +"_contract_" + formprefix + "_id").change(a);

    });
    a();
}