export function preselect_tenant_by_apartment(formprefix) {
    var tenant = $("#" + formprefix + "_tenant_id");
    var apartment = $("#" + formprefix + "_apartment_id");
    let options = tenant.find("option");
    apartment.change(() => {
        let apartment_id = apartment.val();
        if (!apartment_id) {
            return;
        }
        $.get('/apartments/' + apartment_id + '/last_contract', (e) => {
            let chosen = null;
            let tenant_id_to_search = e ? e.tenant_id : '';

            options.each((i2, option) => {
                option = $(option);
                let option_tenant_id = option.attr("value").toLowerCase();
                if (tenant_id_to_search === "") {
                    if (option.attr('selected') == null) {
                        option.attr("selected", null);
                    }
                    return;
                }
                option.attr("hidden", (option_tenant_id.includes(tenant_id_to_search) ? null : 'asd'));
                if (chosen == null && option_tenant_id.includes(tenant_id_to_search)) {
                    chosen = option
                }
            });

            if (chosen == null) {
                options.attr("hidden", null);
                return;
            }

            options.each((i2, e2) => {
                let e3 = $(e2);
                let newval = (e2 === chosen[0] ? 'selected' : null);
                if (e3.attr('selected') !== newval) {
                    e3.attr('selected', newval);
                }
            });
        });

    });
}