export function toggle_extern_data() {

    function a() {
        let isChecked = te[0].checked || tft[0].checked;
        $("#tenant_extern_street").attr("disabled", isChecked ? null : '')
        $("#tenant_extern_city").attr("disabled", isChecked ? null : '')
        $("#tenant_extern_number").attr("disabled", isChecked ? null : '')
        $("#tenant_extern_zip").attr("disabled", isChecked ? null : '')
    }

    let te = $("#tenant_extern");
    te.change(a);

    let tft = $("#tenant_former_tenant");
    tft.change(a);

}