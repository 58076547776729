function validateFormWithTabs(form) {
    let f = $(form);
    f.attr("novalidate", "novalidate");
    f.submit((e) => {
        let valid = true;
        $(e.target).find('input, textarea, select').each(function (i, e) {
            if (!e.checkValidity()) {
                $("#form-warning").removeClass("d-none");
                let tab = $(e).parents(".tab-pane");
                $("a[href='#" + tab.attr("id") + "']").tab("show");
                setTimeout(() => {
                    e.reportValidity()
                }, 250);
                valid = false;
                return false;
            }
        });
        return valid;
    });
}

class unsavedchanges{
    constructor(form) {
        let f = $(form);
        this.form = f;
        this.do_submit = false;
        this.initstate = f.serialize();
        $(window).on("beforeunload", this.windowleave.bind(this));
        f.submit(this.submit.bind(this));
    }

    submit(e) {
        if (this.form[0].checkValidity()) {
            this.do_submit = true;
        } else {
            return false;
        }
    }

    windowleave(e) {
        if (!this.do_submit && this.form.serialize() != this.initstate) {
            return false;
        }
    }

}

export function warn_unchanged_forms() {
    $("form").each((i, e) => {
        validateFormWithTabs(e);
        new unsavedchanges(e);
    });
}