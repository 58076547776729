
export function checkPassword() {
    let zxcvbn = require('zxcvbn');
    $(".row.password_strength").each((i,e) => {
        let input = $(e).find('input');
        let pbar = $(e).find('.progress-bar');
        let tipps = $(e).find('.tipps');

        input.on("input", () => {
            let measure = zxcvbn(input.val());
            let score = measure.score;
            let text = '';
            let color = '';
            pbar.css('width', ((score + 1) * 20) + "%");
            switch (score) {
                case 0:
                    text = 'Sehr Schwach!';
                    color = 'danger';
                    break;
                case 1:
                    text = 'Schwach';
                    color = 'danger';
                    break;
                case 2:
                    text = 'Okay';
                    color = 'warning';
                    break;
                case 3:
                    text = 'Gut!'
                    color = 'success';
                    break;
                case 4:
                    text = 'Hervorragend!'
                    color = 'success';
                    break;
            }
            if (color !== 'danger') {
                pbar.removeClass('bg-danger');
            }
            if (color !== 'warning') {
                pbar.removeClass('bg-warning');
            }
            if (color !== 'success') {
                pbar.removeClass('bg-success');
            }
            pbar.addClass('bg-' + color);
            pbar.text(text);
            console.log(measure);
        });
    });

}