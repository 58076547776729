export function initSearch() {

    (function() {

        var form = $(".simple_form.q");
        if (form.length == 0) {
            return;
        }

        var addbutton = form.find("#unlockSearchCondition");
        var select = form.find("#selectSearchCondition");

        addbutton.click(() => {
            let selected = select.val();

            if (selected === null) {
                return false;
            }

            $(".modal-body .additional#" + selected).show();
            select.find("option[value='" + selected + "']").remove();
            return false;
        });

        form.find(".modal-body .additional").each((i, e) => {
            select.append($("<option>").text($(e).find('label').text()).val(e.id));
        });


        $('#search').on('shown.bs.modal', function () {
            $('#search input.form-control:first').focus();
        }) ;

    })();

}